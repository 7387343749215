import React, { useState } from "react";
import "./index.css"; // Adjust the path based on your file structure
import { FaQuestion } from "react-icons/fa";

// Predefined RGB values for specific wavelengths (skeleton)
const predefinedRGBValues = {
  // Taken from Inoue et al., Appl. Phys. Lett. 98(9), 93113 (2011)
  200: { R: 7, G: 11, B: 72 },
  220: { R: 22, G: 41, B: 133 },
  240: { R: 49, G: 105, B: 180 },
  260: { R: 67, G: 192, B: 240 },
  280: { R: 124, G: 202, B: 214 },
  300: { R: 162, G: 216, B: 228 },
  320: { R: 224, G: 239, B: 219 },
  340: { R: 252, G: 250, B: 213 },
  360: { R: 254, G: 250, B: 200 },
  380: { R: 255, G: 237, B: 180 },
  400: { R: 235, G: 236, B: 123 },
  420: { R: 213, G: 223, B: 46 },
  440: { R: 222, G: 222, B: 0 },
  460: { R: 243, G: 200, B: 0 },
  480: { R: 244, G: 157, B: 0 },
  500: { R: 238, G: 111, B: 2 },
  520: { R: 211, G: 72, B: 47 },
  540: { R: 180, G: 59, B: 53 },
  560: { R: 141, G: 57, B: 53 },
  580: { R: 144, G: 54, B: 60 },
  600: { R: 43, G: 77, B: 55 },
  620: { R: 50, G: 82, B: 54 },
  640: { R: 60, G: 86, B: 60 },
  660: { R: 69, G: 87, B: 71 },
  680: { R: 77, G: 91, B: 77 },
  700: { R: 77, G: 93, B: 81 },
};

function interpolateRGB(wavelength) {
  const lowerBound = Math.floor(wavelength / 20) * 20;
  const upperBound = lowerBound + 20;

  // Get lower and upper RGB values
  const lowerRGB = predefinedRGBValues[lowerBound] || { R: 0, G: 0, B: 0 };
  const upperRGB = predefinedRGBValues[upperBound] || { R: 0, G: 0, B: 0 };

  // Calculate proportion
  const proportion = (wavelength - lowerBound) / (upperBound - lowerBound);

  // Interpolate RGB values
  const R = lowerRGB.R + proportion * (upperRGB.R - lowerRGB.R);
  const G = lowerRGB.G + proportion * (upperRGB.G - lowerRGB.G);
  const B = lowerRGB.B + proportion * (upperRGB.B - lowerRGB.B);

  return `rgb(${Math.round(R)}, ${Math.round(G)}, ${Math.round(B)})`;
}

function App() {
  const [wavelength, setWavelength] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("white");
  const [inputBgOpacity, setInputBgOpacity] = useState(1); // Control the opacity of input background
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleChange = (e) => {
    const wl = parseInt(e.target.value, 10);
    setWavelength(e.target.value);

    if (!isNaN(wl) && wl >= 200 && wl <= 700) {
      setBackgroundColor(interpolateRGB(wl));
      setInputBgOpacity(0.1); // Set opacity to 0.1 when a valid color is entered
    } else {
      setBackgroundColor("white");
      setInputBgOpacity(1); // Reset opacity to 1 when the input is invalid
    }
  };

  // Style for the container
  const appStyle = {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: backgroundColor,
    transition: "background-color 0.5s ease",
  };

  // Dynamic style for the input, adjusting the opacity of the background
  const inputStyle = {
    opacity: 1, // Keep text fully opaque
    width: "80%",
    maxWidth: "300px",
    height: "50px",
    textAlign: "center",
    borderRadius: "25px",
    border: "2px solid #ccc",
    fontSize: "1.2rem",
    padding: "0 20px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease",
    backgroundColor: `rgba(255, 255, 255, ${inputBgOpacity})`, // Control background opacity dynamically,
    outline: "none",
  };

  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "20px",
    zIndex: 1000,
    width: "80%",
    maxWidth: "400px",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  };

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  };

  return (
    <div style={appStyle}>
      <input
        type="text"
        value={wavelength}
        onChange={handleChange}
        placeholder="Enter wavelength in nm"
        style={inputStyle}
      />
      <button
        onClick={() => setIsModalOpen(true)}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          padding: "10px",
          fontSize: "24px", // Adjusted to ensure the icon size is appropriate
          borderRadius: "50%",
          backgroundColor: "#000",
          color: "#FFF",
          border: "none",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FaQuestion />
      </button>
      {isModalOpen && (
        <div style={overlayStyle} onClick={() => setIsModalOpen(false)}>
          <div style={modalStyle} onClick={(e) => e.stopPropagation()}>
            <div style={{ textAlign: "right" }}>
              <button
                onClick={() => setIsModalOpen(false)}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "30px",
                  cursor: "pointer",
                }}
              >
                &times;
              </button>
            </div>
            <h3
              style={{ color: "#333", marginBottom: "10px", fontSize: "24px" }}
            >
              About the Absorption Wavelength To Color Visualizer
            </h3>
            <p style={{ color: "#555", fontSize: "16px", lineHeight: "1.5" }}>
              This tool is designed to visually interpret the color outcome
              based on the absorption wavelength, specifically within the
              context of plasmonic nanoparticles. By inputting a wavelength
              value between 200 nm and 700 nm, you can observe the color that
              corresponds to the solution's appearance due to the plasmonic
              resonance effect.
            </p>
            <div
              style={{
                marginTop: "20px",
                textAlign: "right",
                fontSize: "12px",
              }}
            >
              &copy; 2024 Alexander Al-Feghali{" "}
              <a
                style={{
                  color: "#0000EE", // Traditional blue for links
                  textDecoration: "underline", // Underline to make it visibly clickable
                  cursor: "pointer", // Change cursor to indicate it's a link
                }}
                target="_blank"
                rel="noopener noreferrer" // Security measure for opening links in new tab
                href="https://www.abutarator.com"
              >
                abutarator.com
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
